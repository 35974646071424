import React, { Component } from 'react';
import { Route, Redirect, Link } from 'react-router-dom';

import { history } from '../helpers';
import NavBar from '../components/NavBar';
import NavBarMobile from '../components/NavBarMobile';
import Footer from '../components/Footer';

export const DefaultLayout = ({component: Component, ...rest}) => {
    const isMobile = window.innerWidth < window.innerHeight;
    // console.warn(window.innerWidth + "/" + window.innerHeight);
    // console.warn(isMobile);
    if(!isMobile){
        return (
            // Desktop Layout
            <Route history={history} {...rest} render={matchProps => (
                <div className="DefaultLayoutDesktop">
                    <div className="container-flex view-container">
                        <div className="content-wrap">
                            <div class="container-fluid">
                                <div class="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <NavBar></NavBar>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <Component {...matchProps} />
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid bg-footer footer">     
                            <div className="row">
                                <div className="col-md-2 bg-footer"></div>
                                    <div className="col-md-8 bg-footer">
                                    <Footer></Footer>
                                    </div>
                                <div className="col-md-2 bg-footer"></div>                                
                            </div>
                        </div>
                    </div>
                </div>
            )} />
        )
      }
      else{
        return (
            <Route history={history} {...rest} render={matchProps => (
                <div className="DefaultLayoutMobile">
                    <div className="container-flex view-container">
                    <div className="content-wrap">
                            <div class="container-fluid">
                                <div class="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <NavBarMobile></NavBarMobile>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-8">
                                        <Component {...matchProps} />
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid bg-footer footer">     
                            <div className="row">
                                <div className="col-md-2 bg-footer"></div>
                                    <div className="col-md-8 bg-footer">
                                    <Footer></Footer>
                                    </div>
                                <div className="col-md-2 bg-footer"></div>                                
                            </div>
                        </div>
                    </div>
                </div>
            )} />
        )
      }
  };