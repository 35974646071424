import React from 'react';
import { Router, Route, NavLink } from 'react-router-dom';
import { Modal } from "react-bootstrap";
var createReactClass = require('create-react-class');

class EditModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props
        };
    }

    getInitialState() {
        return { 
            showModal: false,
            user: null
        }
    }

    close() {
      this.setState({ showModal: false });
    }
  
    open() {
      this.setState({ showModal: true });
    }
    
    componentDidMount() {
      this.setState({ 
        user: this.props
      })
      console.warn(this.state.user);
    }
  
    render() {
        const { user } = this.state;
      return (
          <div>
            {user &&
                <Modal show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p ref="user._id">{user._id}</p>
                </Modal.Body>
                <Modal.Footer>
                    <p ref="user.email">{user.email}</p>
                </Modal.Footer>
                </Modal>
            }        
        </div>
        )
    }
}


  export default EditModal;