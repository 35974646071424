import React from "react";
//import _ from 'lodash';
import "../App.css";
//import Button from '@material-ui/core/Button';

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const isMobile = window.innerWidth < window.innerHeight;
    if (!isMobile) {
      return (
        <div>
          {/* Project Showcase */}
          <div className='pt-4'>
            <h3>Recent Work</h3>
            {/* Row 1 */}
            <div className='row pt-3 pb-3'>
              <div className='col-sm-4'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>
                      Nationwide ELD / Vehicle tracking and management
                    </h5>
                    <p className='card-text'>
                      <small>January 2020 - Ongoing</small>
                      <br />
                      The main goal of this system is to provide a way to track
                      vehicles' location, engine data and DTC codes in real
                      time, and log drivers' hours of service. <br />
                      - Managed and designed the entire system.
                      <br />
                      - Implemented the endpoints and services required to
                      store, access and analyze the tracking data.
                      <br /> - Implemented an internal geocoding api.
                      <br /> - Developed the public and internal user interfaces
                      for the system.
                      <br />
                    </p>
                    <a href='eld.nwt.ai' className='btn btn-main'>
                      eld.nwt.ai
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title">Personal website redesign</h5>
                                        <p className="card-text">
                                            <small>January 2020 - Present</small><br/>
                                            A complete redesign of my personal website. 
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </p>
                                        <a href="" className="btn btn-main">rares.dev</a>
                                    </div>
                                </div>
                            </div> */}
              <div className='col-sm-4'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>Telepat</h5>
                    <p className='card-text'>
                      <small>July 2018 - January 2019</small>
                      <br />
                      Developed and maintained front and back end applications
                      for some of Telepat's projects.
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                    <a
                      href='https://labs.telepat.io'
                      target='_blank'
                      className='btn btn-main'
                    >
                      Telepat Labs
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>
                      Game Server Management Portal
                    </h5>
                    <p className='card-text'>
                      <small>Summer 2016</small>
                      <br />A web application designed to help people easily
                      manage multiple game servers from a single endpoint,
                      without the hassle of connecting to each server every time
                      a configuration change needs to be applied.
                    </p>
                    <span
                      class='d-inline-block'
                      tabindex='0'
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Project discontinued'
                    >
                      <a
                        href=''
                        target='_blank'
                        className='btn btn-main disabled'
                      >
                        GSMP
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* Row 2 */}
            <div className='row pt-3 pb-3'>
              {/* <div className="col-sm-4">
                                <div className="card h-100">
                                    <div className="card-body">
                                        <h5 className="card-title">Game Server Management Portal</h5>
                                        <p className="card-text">
                                            <small>Summer 2016</small><br/>
                                            A web application designed to help people easily manage multiple game servers from a signle endpoint, without the hassle of
                                            connecting to each server every time a configuration change needs to be applied.
                                        </p>
                                        <span class="d-inline-block" tabindex="0" data-toggle="tooltip" data-placement="top" title="Project discontinued">
                                            <a href="" target="_blank" className="btn btn-main disabled">GSMP</a>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
              <div className='col-sm-4'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>Lenoty Learning</h5>
                    <p className='card-text'>
                      <small>October 2016 - February 2017</small>
                      <br />
                      An online learning platform developed as a project for the{" "}
                      <b>firStep 2017</b> competition.
                      <br />
                      <br />
                      <br />
                    </p>
                    <span
                      class='d-inline-block'
                      tabindex='0'
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Project discontinued'
                    >
                      <a
                        href=''
                        target='_blank'
                        className='btn btn-main disabled pe-n'
                      >
                        Leonty Learning
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='card h-100'>
                  <div className='card-body'>
                    <h5 className='card-title'>Student Portal</h5>
                    <p className='card-text'>
                      <small>February 2016 - May 2016</small>
                      <br />A web application designed to help students view
                      their timetable, communicate with teachers, and receive
                      various notices from the school administration or
                      teachers.
                    </p>
                    <span
                      class='d-inline-block'
                      tabindex='0'
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Project discontinued'
                    >
                      <a
                        href=''
                        target='_blank'
                        className='btn btn-main disabled'
                      >
                        Student Portal
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {/* Project Showcase */}
          <div className='pt-4 pb-5'>
            <h3>Recent Work</h3>
            {/* Row 1 */}
            <div className='pt-3 pb-3'>
              <div className='card h-100'>
                <div className='card-body'>
                  <h5 className='card-title'>Personal website redesign</h5>
                  <p className='card-text'>
                    <small>January 2020 - Present</small>
                    <br />
                    A complete redesign of my personal website.
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                  <a href='' className='btn btn-main'>
                    rares.dev
                  </a>
                </div>
              </div>
            </div>
            <div className='pt-3 pb-3'>
              <div className='card h-100'>
                <div className='card-body'>
                  <h5 className='card-title'>Telepat</h5>
                  <p className='card-text'>
                    <small>July 2018 - January 2019</small>
                    <br />
                    Developed and maintained front and back end applications for
                    some of Telepat's projects.
                    <br />
                    <br />
                    <br />
                    <br />
                  </p>
                  <a
                    href='https://labs.telepat.io'
                    target='_blank'
                    className='btn btn-main'
                  >
                    Telepat Labs
                  </a>
                </div>
              </div>
            </div>
            <div className='pt-3 pb-3'>
              <div className='card h-100'>
                <div className='card-body'>
                  <h5 className='card-title'>Game Server Management Portal</h5>
                  <p className='card-text'>
                    <small>Summer 2016</small>
                    <br />A web application designed to help people easily
                    manage multiple game servers from a single endpoint, without
                    the hassle of connecting to each server every time a
                    configuration change needs to be applied.
                  </p>
                  <span
                    class='d-inline-block'
                    tabindex='0'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Project discontinued'
                  >
                    <a
                      href=''
                      target='_blank'
                      className='btn btn-main disabled'
                    >
                      GSMP
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className='pt-3 pb-3'>
              <div className='card h-100'>
                <div className='card-body'>
                  <h5 className='card-title'>Lenoty Learning</h5>
                  <p className='card-text'>
                    <small>October 2016 - February 2017</small>
                    <br />
                    An online learning platform developed as a project for the{" "}
                    <b>firStep 2017</b> competition.
                    <br />
                    <br />
                    <br />
                  </p>
                  <span
                    class='d-inline-block'
                    tabindex='0'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Project discontinued'
                  >
                    <a
                      href=''
                      target='_blank'
                      className='btn btn-main disabled pe-n'
                    >
                      Leonty Learning
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className='pt-3 pb-3'>
              <div className='card h-100'>
                <div className='card-body'>
                  <h5 className='card-title'>Student Portal</h5>
                  <p className='card-text'>
                    <small>February 2016 - May 2016</small>
                    <br />A web application designed to help students view their
                    timetable, communicate with teachers, and receive various
                    notices from the school administration or teachers.
                  </p>
                  <span
                    class='d-inline-block'
                    tabindex='0'
                    data-toggle='tooltip'
                    data-placement='top'
                    title='Project discontinued'
                  >
                    <a
                      href=''
                      target='_blank'
                      className='btn btn-main disabled'
                    >
                      Student Portal
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export { HomePage };
