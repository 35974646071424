import React from 'react';
//import _ from 'lodash';
import '../App.css';
//import Button from '@material-ui/core/Button';


class AboutPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="pt-5 mx-auto">
                <div className="row pt-5 pb-5 mx-auto">
                    {/* Hi there! */}
                    <div className="col-md-4">
                        <h1 className="h0"><span className="highlight">Hi there!</span> I'm Rares, a Web Developer from Romania.</h1>
                    </div>
                    {/* Bio Showcase */}
                    <div className="col-md-8">
                        <p className="ap">I'm a self-taught developer, with a passion for electronics, aviation and photography.</p>
                        <p className="ap">Since I was a child I was passioned by computers and electronics, so at an early age I started learning about how they work
                            and how to program them to do various tasks. While I was in high-school I built and programmed my first autonomous robots,
                            and various web applications designed for competitions or, in some cases, just for fun.
                        </p>
                        <p className="ap">
                            I previously worked at Telepat as a Web Developer, where I gained a lot of experience developing front and back end applications in Node.js,
                            learned how to configure and deploy Docker containers using Docker Hub and Kubernetes and solidified my programming skills while learning many of the leading industry standards.
                        </p>
                        <p className="ap">
                        {/* Maybe mention freetime drone stuff and photography. */}
                        </p>
                    </div>
                </div>
            </div>
        );
    }

}

export { AboutPage };