import React from 'react';
//import _ from 'lodash';
import '../../App.css';
//import Button from '@material-ui/core/Button';


class E404Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div>
                <div>
                    {/* <Button variant="contained" color="primary">
                        Hello World
                    </Button> */}
                </div>
                <div>
                    <div>
                        <h1>Hi!</h1>
                        <p>This is E404Page</p>
                    </div>
                </div>
            </div>
        );
    }
}

export { E404Page };