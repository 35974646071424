/* eslint-disable no-restricted-globals */
import { authenticationService } from '../services';

export function handleResponse(response){
    console.error('1');
    return response.text().then(text => {
        if (!response.ok){
            if ([401, 403].indexOf(response.status) !== -1){
                // logout on 401 or 403
                console.error(authenticationService.currentUserValue);
                //if(authenticationService.currentUserValue)
                    authenticationService.logout();
                //Usefull on pages other than LOGIN
                //location.reload(true);
            }
            else if ([400].indexOf(response.status) !== -1){
                    console.error('false');
                    return false;
            }
            const data = text && JSON.parse(text);
            const error = (data && data.message) || response.statusText;
            console.log(error);
            return Promise.reject(error);
        }
        const data = text && JSON.parse(text);
        console.error('ret');
        return data;
    })
}