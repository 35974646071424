import React from "react";
//import _ from 'lodash';
import "../App.css";
//import Button from '@material-ui/core/Button';

class RSAPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="pt-5 mx-auto">
        <div className="row pt-5 pb-5 mx-auto">
          <div className="col-md-12">
            <p>
              -----BEGIN PGP PUBLIC KEY BLOCK-----
              mQINBF5x5DgBEADBFbTTGs4iNUrNK2U19u69CxH8O2VlwyXYRlQ2W6J0bIPtM4y1
              0GcEJ1Q/JASfQJg1SeQzbHuku0OWqYl1I7NIXgICyLaY2OOhIKH2bu3M0S91cFNI
              WnysjYPU2SO2TkeiM+7zoIm+smm9chJvyvDknQwVTz57NtbwTEIqcDFqiU/986VX
              dlnbWLnLcl0pevtmfn/0F0boRhULbIC+++11UloDeK/XAbbLbdM5DccnUEayINtR
              Q/vzM6uVM2nyw4EFAkEJjh+3L4CpjA6LH1Q46klxvIThKgTuHtUMOfgRXb39FsEq
              /O+RGS7HblUHKCZAqy1vtzqeIZ9ENIOwqn6PafIGzObMT7HpdmNEbz2tu6k2lCrw
              5lJNtLTJPgktrYe4l/d8DR52XqHpiE/HaaTqgmg+d7w2AksvMt1C5/E4sERHBDvE
              nOJ5qojEuAkM/TFdSSBArAPJnlw2vU8cA8CP4qTc0LF7zVD/rFwP6KhxVm/YGW1F
              fETcRO+Si1CJ3RtCAIiGjR2PiOAfiCwOFIuV4uX+I1EVUuPRZnR7FkSPEzrNeNB3
              ZfVWQGroapUQxPuE1FtZvd8St65gieNYmwjF4y+S1s0PJnBLvL+xr5yaO0Yoeuiv
              Je1cVaU98IVLGBsBf2CEjJwmC9GOQVGTP3/pgg9oJ+ZTfxkDcG19+ulw3wARAQAB
              tBdSYXJlcyA8cmFyZXNAcmFyZXMuZGV2PokCVAQTAQgAPhYhBBx9LZ3YTPxIPDcO
              qCwBCFHXOzcFBQJeceQ4AhsjBQkDwnPoBQsJCAcCBhUKCQgLAgQWAgMBAh4BAheA
              AAoJECwBCFHXOzcFtZwQAJiHlkDcsDshUb0IU1rSRCh0ZCg31e39NEUhkUCQYyve
              5FGmeKdUMJddB0R3mxTMkD9nNNsykPxJZhpTn5S3cxqNiWo9jhJXcdTALrHoomNd
              /ZJBvZG1d6YF1fgKBcCD0aJeJE7y3jjJCWM8mh+HGYd2ll3gQLB3vXcR+l8Q1OSN
              CodsKxX0kjWbsstFFuiq+w/d1sBDlQUD26vyzfZbraup0c2Ps+SWEyIxn9r8v5eL
              5EycU5MR6tydHdNDIp0K2uYLZW+5GOv0BLJp8jV1p5pJtjDIgM8DygWUhYUl3/Tp
              1AuTFh9f8b+Cg046Ns/MX2DOq8xbIDisSYy621Z0KM7h3IizLcWGhHYIR/bLKQv0
              nDwDisRq6jakQgGBnDveArG7vb/hfVKTMAbpiS2QroxAxQJZljYPH3abkgNGVqGb
              7QAFBtwZ0Vv1rbuhetJiA9tbRW7oSXhP1NRFg6opI+JpIBilbW314A6Q11TuIrZQ
              sYiGz0xNzf5DLCSLhnnWWpITnmJepF/xkQ9yq4EgfeIV5uACsb659wTYyfzg2/4L
              lR5GjVC081MIP2B7x21YkGrXlegOKDN9OaVKc/3IX3wv0p4o+2TzgI+QNLdd30w4
              aO96Ze8eOY1I07ZSgBIHRDnsy4qkbTmaGfSmJ7MzjXLr5jz9kESXvDQYpqDCg1Jq
              uQINBF5x5DgBEADlKO4feoSyV6qGkc9VmoR8N9QYlYFz3dnuqZ71ImFkGoZqiTxX
              m1WCzZp0b/Rl4WrmnhC6WOKJFu3ocVw2lGgOilSYbpaXox2OLdEgaFBw3MEJjHPK
              /z40BpkfqAQfqjzLSWaa3q1hMSut38AC3TVCyYYMBWm5Q4grBW/L4U2EDfqm5p/L
              gfZ2/OnDJ8Qx5qdyUiOF8GwSFZ+gPAbw+p6vRSyeghDHL7Vx6omwy7B3mIcUk3B9
              oIqCFVr6pHyHyXO9uG6PyJev3SPABQN2cQPW4Nj1S0R8rYEuHayJqAdjWBRd73zh
              0GrnJai7TNg5WfQpxrzJNWe75eH33mAl+jNZxxLxFzJQVWf+vi8PEeuiuzWj/8tM
              a2hpwUrM0aMd1Lje8jL61+JzCJ8oQi7wQZODNewtMpkKv5CVKoco0rQ9s+01lRco
              zxCRqgzdb+8yTMAGc501GWWnCB6qnlSRVuOSpN4yubfDU8E/w304fsLqLzeeWPZY
              deJw9h81wyEpPraFMw+ChkTMZBqZXWY7knX93nMpfh+5vIQ7icNZHO3MotdnL2Li
              Hin35FexiO3xtsGQC5W6+PL5FqmECJzyGlZUC+PeYFUn9oHMoPd5Rogtg1sAGvXA
              kC+R+hNAO4DSQCIpoUAJZu/bIp1JJ/M2Cx9oz3WgWoEGqLl22MycnAwe5QARAQAB
              iQI8BBgBCAAmFiEEHH0tndhM/Eg8Nw6oLAEIUdc7NwUFAl5x5DgCGwwFCQPCc+gA
              CgkQLAEIUdc7NwXUwg/5AVrUu6gnRSGbFPuoby8GxEl81eMh+jL2nO/F2574AMdd
              RE65vJt9KT7/s6GnCyrJrinHqdVix9HtvoMkkK+V5i7uVFRvKpStgVZakn0oCYfQ
              3/mHafODSXEO02CGX45H/qDNGNMHzhs2nVpw5MpIZxAPbeTD33JwgmIi4e19j0E9
              4wnnQT3xeaSAR5rC4Jygoovft4yDh3O1pEtvMEbk/00dHP7COAHn1Sh/GBvzRHoS
              OqERpl1fAE3zlfHjB2I3BEs8x1gX/PEBhnFNRwNXV7NPbCO1zM3s1rdDzIF7rkKi
              F96no1A6/X6RnweBvr9gtN3O8dTI8UPaFlTQlyew2lzm4V4ArV8bTUsbHg/NSNuy
              ughG+2s/svdmJkdzDwSrBimaetbnyTun1QLxFSFUyUqbXUxoz8MtlGN4ApD+C/cB
              pe6EVVJ5tQHKYfcZ2I/gTanBidLAVinISVSpvq1+ddXg3i/ItrKT8GK3eyDz3p1t
              WjYHCAAyGi9IGuwB2ubR5u0RD4q/mN5op+gxdjbbwc7H1YPwwnmrRY1JQGdTfZLS
              Bi9aqmqmcm9tnHMzxdzdybT6pGWLjAFGR8AfyYlffArmUm8gThg7Vp4g8PFly/fb
              JTJMhMsE+YJa5+I2GiT8POAmOcoY4qMFmzoskQFFCxcTTUCbHcxtUwOg3TUxIgQ=
              =Q7KU -----END PGP PUBLIC KEY BLOCK-----
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export { RSAPage };
