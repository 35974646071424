import React from 'react';
import { Router, Route, Link, NavLink } from 'react-router-dom';

import { history } from '../helpers';
// import { authenticationService } from '../services';

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // currentUser: null
        };
    }

    // componentDidMount() {
    //     authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    // }

    // logout() {
    //     authenticationService.logout();
    //     history.push('/login');
    // }

    render() {
        const { currentUser } = this.state;
        return (
            <Router history={history}>
                    <nav className="navbar navbar-expand-md justify-content-between"> 
                        <div class="navbar-nav mr-auto"> 
                            <a className="navbar-brand header-item" href="/">
                                <h3>Rares Sfetcu</h3>
                            </a>    
                        </div>     
                        <div class="navbar-nav ml-auto">
                                <NavLink
                                    className="nav-item nav-link navbar-right header-item"
                                    to="/"
                                    isActive={(match, location) => location.pathname == '/'}
                                    >
                                        About
                                </NavLink>
                                <NavLink
                                    className="nav-item nav-link header-item"
                                    to="/work"
                                    isActive={(match, location) => location.pathname.startsWith('/work')}
                                    >
                                        Work
                                </NavLink>
                                <NavLink
                                    className="nav-link header-item"
                                    to="/resume"
                                    isActive={(match, location) => location.pathname.startsWith('/resume')}
                                    >
                                        Resume
                                </NavLink>
                                <NavLink
                                    className="nav-item nav-link header-item"
                                    to="/contact"
                                    isActive={(match, location) => location.pathname.startsWith('/contact')}
                                    >
                                        Contact
                                </NavLink>
                        </div>
                    </nav>
            </Router>
        );
    }

    
}

export default NavBar;