import React from 'react';
import { Router, Route, Link, NavLink } from 'react-router-dom';

import { history } from '../helpers';
// import { authenticationService } from '../services';

class NavBarMobile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // currentUser: null
        };
    }

    // componentDidMount() {
    //     authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
    // }

    // logout() {
    //     authenticationService.logout();
    //     history.push('/login');
    // }

    render() {
        const { currentUser } = this.state;
        return (
            <Router history={history}>
                    <nav className="navbar navbar-expand-md justify-content-between"> 
                        <a className="navbar-brand header-item" href="/">
                            <h3>Rares Sfetcu</h3>
                        </a>    
                        <button className="navbar-toggler toggler-example" type="button" data-toggle="collapse" data-target="#navbarSupportedContent1"
                        aria-controls="navbarSupportedContent1" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="dark-blue-text navbar-toggler-icon">
                                <i className="fas fa-bars fa-1x"></i>
                            </span>
                        </button>     
                        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                            <div className="navbar-nav ml-auto">
                                <NavLink
                                    className="nav-item nav-link navbar-right header-item"
                                    to="/"
                                    isActive={(match, location) => location.pathname == '/'}
                                    >
                                        About
                                </NavLink>
                                <NavLink
                                    className="nav-item nav-link header-item"
                                    to="/work"
                                    isActive={(match, location) => location.pathname.startsWith('/work')}
                                    >
                                        Work
                                </NavLink>
                                <NavLink
                                    className="nav-link header-item"
                                    to="/resume"
                                    isActive={(match, location) => location.pathname.startsWith('/resume')}
                                    >
                                        Resume
                                </NavLink>
                                <NavLink
                                    className="nav-item nav-link header-item"
                                    to="/contact"
                                    isActive={(match, location) => location.pathname.startsWith('/contact')}
                                    >
                                        Contact
                                </NavLink>
                            </div>
                        </div>
                    </nav>
            </Router>
        );
    }

    
}

export default NavBarMobile;