import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

//import { authenticationService } from '../services';
import { DefaultLayout } from '../layouts/DefaultLayout';

export const PrivateRoute = ({component: Component, ...rest}) =>(
    <Route {...rest} render = {props => {
        console.warn('rest...:' + {...rest});
        //const currentUser = authenticationService.currentUserValue;
        // if (!currentUser){
        //     //Not logged in, redirect to login page
        //     return <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        // }
        //Authed, return the actual component
        return <DefaultLayout path="/" component={Component}/>
    }} />
)