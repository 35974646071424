import React, { Fragment } from "react";
import DocumentMeta from "react-document-meta";
import { Router, Route, Redirect, Link } from "react-router-dom";

import { history } from "./helpers";
// import { authenticationService } from './services';
import { PrivateRoute } from "./components";
import {
  HomePage,
  AboutPage,
  ContactPage,
  ResumePage,
  E404Page,
  RSAPage
} from "./Pages";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

const meta = {
  title: "Rares Sfetcu - Full-stack developer",
  description:
    "Rares Sfetcu, a full-stack developer based in Bucharest, Romania.",
  // canonical: 'http://example.com/path/to/page',
  meta: {
    charset: "utf-8",
    name: {
      keywords: "rares,sfetcu,web,developer,react"
    }
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentDidMount() {
  //     authenticationService.currentUser.subscribe(x => this.setState({ currentUser: x }));
  // }

  // logout() {
  //     authenticationService.logout();
  //     history.push('/login');
  // }

  render() {
    const { currentUser } = this.state;
    return (
      <Router history={history}>
        <DocumentMeta {...meta} />
        {/* <div className="wrapper"> */}
        <PrivateRoute exact path="/" component={AboutPage} />
        <PrivateRoute exact path="/work" component={HomePage} />
        <PrivateRoute exact path="/resume" component={ResumePage} />
        <PrivateRoute exact path="/contact" component={ContactPage} />
        <PrivateRoute exact path="/rsa" component={RSAPage} />
        {/* <Route path="/login" component={LoginPage} /> */}
        {/* <Route path="*" component={E404Page} />
                    {console.warn(history)} */}
        {/* Fallback 404 */}
        {/* <Redirect to='/404' /> */}
        {/* </div> */}
      </Router>
    );
  }
}

export default App;
