import React from 'react';
import { Router, Route, Link, NavLink } from 'react-router-dom';

import { history } from '../helpers';

class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        return (
            <Router history={history}>
                    <nav className="navbar navbar-expand-md bg-footer justify-content-between"> 
                        <div class="navbar-nav mr-auto"> 
                            <a className="navbar-brand footer-item" href="/">
                                <h3>Rares Sfetcu</h3>
                            </a>    
                        </div>     
                        <div class="navbar-nav ml-auto">
                                {/* <a className="nav-item nav-link navbar-right footer-item" href="https://github.com/RaresKool" target="_blank">LinkedIn</a> */}
                                <a className="nav-item nav-link navbar-right footer-item" href="https://github.com/RaresKool" target="_blank">GitHub</a>
                        </div>
                    </nav>
            </Router>
        );
    }

    
}

export default Footer;