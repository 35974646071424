import React from "react";
//import _ from 'lodash';
import "../App.css";
//import Button from '@material-ui/core/Button';

class ResumePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className='row pt-5 pb-5 mx-auto'>
        {/* Left Pad */}
        <div className='col-md-2'></div>
        <div className='col-md-6'>
          <p>
            I'm a self-taught developer, seeking to secure an entry-level
            position. Throughout my professional experiences I have used various
            frameworks to work with others on developing engaging projects. I
            hope to share my skills and experience with your organization and
            clients.
          </p>
          <hr />
          <h1>Skills</h1>
          <div className='row'>
            <div className='col-md-6'>
              <ul>
                <li>
                  Proficient in TypeScript and JavaScript (ES6, React, lodash)
                </li>
                <li>Familiar with GIT, MongoDB, CRON, Java, SQL and C#</li>
                {/* </ul>
                        </div>
                        <div className="col-md-6">
                            <ul> */}
                <li>Strong API design knowledge</li>
                <li>Familiar with test-driven-design methodologies</li>
              </ul>
            </div>
          </div>
          <hr />
          <h1>Work History</h1>
          <div>
            <h4>
              Full Stack Engineer at
              <span className='highlight'>Nationwide Technologies</span>
            </h4>
            <small>January 2020 - </small>
            <ul>
              <li>Managed and designed the entire system.</li>
              <li>
                Implemented the endpoints and services required to store, access
                and analyze the tracking data.
              </li>
              <li>Implemented an internal geocoding api.</li>
              <li>
                Developed the public and internal user interfaces for the
                system.
              </li>
            </ul>
          </div>

          <div>
            <h4>
              Web Developer at <span className='highlight'>Telepat</span>
            </h4>
            <small>July 2018 - January 2019</small>
            <ul>
              <li>Developed front and back end applications.</li>
              <li>
                Solidified my knowledge of promises and async functionality in
                JavaScript.
              </li>
              <li>
                Learned how to use CRON to launch delayed or routine scheduled
                tasks.
              </li>
              <li>
                Learned how to deploy applications using Docker and Kubernetes.
              </li>
              <li>Learned many of the leading industry standards.</li>
            </ul>
          </div>
          <hr />
          <h1>Projects</h1>
          <div>
            <h4>
              <span className='highlight'>Game Server Management Portal</span>
            </h4>
            <small>Summer 2016</small>
            <p>
              A web application designed to help people easily manage multiple
              game servers from a single endpoint, without the hassle of
              connecting to each server every time a configuration change needs
              to be applied.
            </p>
            {/*  */}
            <h4>
              <span className='highlight'>Lenoty Learning</span>
            </h4>
            <small>October 2016 - February 2017</small>
            <p>
              An online learning platform designed to help students learn about
              programming, mathematics, and other subjects at school
              <br />
              Lenoty Learning was my team's entry for <b>firStep 2017</b>.
            </p>
            {/*  */}
            <h4>
              <span className='highlight'>Student Portal</span>
            </h4>
            <small>February 2016 - May 2016</small>
            <p>
              A web application designed to help students view their schedule,
              easily communicate with teachers and receive administrative
              notices from the school.
              <br />
              Student Portal was my team's entry for <b>IYIPO 2016</b>.
            </p>
          </div>
          <p className='ap'>
            {/* Maybe mention freetime drone stuff and photography. */}
          </p>
        </div>
        {/* Right Pad */}
        <div className='col-md-4'>
          {/* <h1 className="h0"><span className="highlight">Hi there!</span> I'm Rares, a Web Developer from Romania.</h1> */}
        </div>
      </div>
    );
  }
}

export { ResumePage };
