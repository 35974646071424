import React from 'react';
//import _ from 'lodash';
import '../App.css';
//import Button from '@material-ui/core/Button';


class ContactPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="row pt-5 pb-5 mx-auto">
                {/* Contact Info */}
                <div>
                    <h1 className="h0">Email me</h1>
                    <p className="ap">Got any questions about me or my work? Interested in hiring me?</p>
                    <p className="ap">Reach out to me at <a className="highlight" href="mailto:rares@rares.dev">rares@rares.dev</a> and let's talk.</p>
                </div>
            </div>
        );
    }

}

export { ContactPage };